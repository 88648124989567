import ChatBotHeader from "../components/ChatBotHeader";

const settings = {
  header: {
    title: <ChatBotHeader />,
  },
  footer: {
    text: "© 2024 Mark. All rights reserved.",
  },
  general: { embedded: true },
  fileAttachment: {
    accept: ".pdf",
    // icon: "/assets/customIcons/fileAttachment1.svg"
  },
  chatHistory: { storageKey: "homeguard_chat_data" },
};

export default settings;
