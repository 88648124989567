import axios from "axios";
import config from "../../settings/config";
import { getToken } from "../../settings/storage";

// Process insurance content document (PDF, DOCX, or TXT)
export const generateInsuranceClaim = async (payload) => {
  try {
    const token = await getToken();
    console.log('checkpoint payload:', payload)

    const response = await axios.post(
      `${config.backendUrl}/client/help-insurance_claim/generate`,
      {
        claimant_name: payload.claim_info.claimantName,
        claimant_email: payload.claim_info.claimantEmail,
        incident_date: payload.claim_info.incidentDate,
        cause_of_loss: payload.claim_info.causeOfLoss,
        incident_location: payload.claim_info.incidentLocation,
        affected_items: payload.claim_info.affectedItems.map(({ id, createdAt, updatedAt, userId, ...rest }) => rest),
        estimated_loss_value: payload.claim_info.estimatedLossValue,
        additional_notes: payload.claim_info.additionalNotes,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'multipart/form-data' // Axios automatically sets this for FormData
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error generating insurance claim:", error);
    throw error;
  }
};
