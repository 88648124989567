import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid2,
  Autocomplete,
  Divider,
  // IconButton,
} from "@mui/material";
import {
  // ImageSearch as ImageViewIcon,
  // AddPhotoAlternate as ImageAddIcon,
  // CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";

// import ImagesPreview from "./ImagesPreview";
import { fetchAllInventoryItems } from "../../services/client/inventoryItemsService";
import { convertSnakeToCamel } from "../../utils/jsonHelpers";
import config from "../../settings/config";

const ItemImage = styled("img")(({ theme }) => ({
  width: 40,
  height: 40,
  marginRight: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const ItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const DetailsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

const RoomName = styled("span")(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.5),
}));

const Description = styled("span")(({ theme }) => ({
  display: "block",
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.primary.main,
}));

const InsuranceClaimInputFields = ({
  insuranceClaimData,
  // imagePreviews,
  // titles,
  // handleNewRoomClick,
  handleChange,
  // handleBrandChange,
  // handleRoomTypeChange,
  // handleSelectImage,
  // handleDeleteImage,
  unauthorizedHandler,
  isFormDisabled = false,
}) => {
  console.log("checkpoint insurance claim data:", insuranceClaimData);
  const [availableItems, setAvailableItems] = useState([]);
  const [affectedItems, setAffectedItems] = useState([]);

  const fetchInventoryItemsCallback = useCallback(async () => {
    try {
      console.log("checkpoint started");
      const response = await fetchAllInventoryItems();
      setAvailableItems(response.data.map((item) => convertSnakeToCamel(item)));
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.detail || "An error occurred", {
          autoClose: 1000,
        });
        if (error.response.status === 401) {
          unauthorizedHandler();
          // navigate("/sign-in")
        }
      } else {
        toast.error("Network error or request failed", { autoClose: 1000 });
      }
    }
  }, [unauthorizedHandler]);

  useEffect(() => {
    fetchInventoryItemsCallback();
  }, [fetchInventoryItemsCallback]);

  // Handle adding item to affected items list
  const handleItemSelect = (event, newValue) => {
    if (newValue) {
      setAffectedItems((prev) => {
        const updatedItems = [...prev, newValue];
        handleChange({
          target: { name: "affectedItems", value: updatedItems },
        }); // Directly update
        return updatedItems;
      });
      setAvailableItems((prev) =>
        prev.filter((item) => item.id !== newValue.id)
      );
    }
  };

  // Handle removing item from affected items list
  const handleItemRemove = (itemToRemove) => {
    setAffectedItems((prev) => {
      const updatedItems = prev.filter((item) => item.id !== itemToRemove.id);
      handleChange({ target: { name: "affectedItems", value: updatedItems } }); // Directly update
      return updatedItems;
    });
    setAvailableItems((prev) => [...prev, itemToRemove]);
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 container size={12}>
        <Grid2 container size={{ xs: 12, sm: 8 }}>
          <Grid2 size={{ xs: 12, sm: 12 }}>
            <label>Claimant Info</label>
          </Grid2>
          <Grid2 size={{ xs: 6, sm: 6 }}>
            <TextField
              label="Name"
              name="claimantName"
              value={insuranceClaimData.claimantName || ""}
              onChange={handleChange}
              fullWidth
              required
              disabled={isFormDisabled}
            />
          </Grid2>
          <Grid2 size={{ xs: 6, sm: 6 }}>
            <TextField
              label="Email"
              name="claimantEmail"
              value={insuranceClaimData.claimantEmail || ""}
              onChange={handleChange}
              fullWidth
              required
              disabled={isFormDisabled}
            />
          </Grid2>
        </Grid2>
      </Grid2>

      <Grid2 size={12}>
        <Divider variant="middle" />
      </Grid2>

      <Grid2 container size={{ xs: 12, sm: 8 }}>
        <Grid2 item="true" size={{ xs: 6, sm: 6 }}>
          <TextField
            label="Incident Date"
            name="incidentDate"
            type="date"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={insuranceClaimData.incidentDate || ""}
            onChange={handleChange}
            fullWidth
            required
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 6, sm: 6 }}>
          <TextField
            label="Cause of Loss"
            name="causeOfLoss"
            value={insuranceClaimData.causeOfLoss || ""}
            onChange={handleChange}
            fullWidth
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 12, sm: 12 }}>
          <TextField
            label="Incident Location"
            name="incidentLocation"
            value={insuranceClaimData.incidentLocation || ""}
            onChange={handleChange}
            fullWidth
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 size={12}>
          <Divider variant="middle" />
        </Grid2>

        {/* Group: Affected Items, Selected Item List */}
        <Grid2 item="true" size={{ xs: 12, sm: 12 }}>
          <Autocomplete
            // freeSolo
            disableClearable
            options={availableItems}
            value={insuranceClaimData.affectedItems || ""}
            getOptionLabel={(option) =>
              option && option.description ? option.description : ""
            }
            renderOption={(props, option) => {
              const { key, ...restProps } = props;

              return (
                <li
                  key={option.id}
                  {...restProps}
                  style={{ padding: "10px", borderBottom: "1px solid #f0f0f0" }}
                >
                  <ItemContainer>
                    <ItemImage
                      src={
                        option.imageUrls[0]
                          ? `${config.backendDomain}/${option.imageUrls[0]}`
                          : "/assets/customIcons/inventory_item_default.ico"
                      }
                      alt={option.description}
                    />
                    <DetailsContainer>
                      <RoomName>{option.roomName}</RoomName>
                      <Description>{option.description}</Description>
                    </DetailsContainer>
                  </ItemContainer>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                label="Affected Items"
                name="affectedItems"
                {...params}
                // onChange={handleChange}
              />
            )}
            onChange={handleItemSelect}
            disabled={isFormDisabled}
          />
        </Grid2>
        <Grid2 item="true" size={{ xs: 12, sm: 12 }}>
          <List>
            {affectedItems.map((item) => (
              <ListItem
                key={item.id}
                secondaryAction={
                  <Box display="flex">
                    {/* <Button
                      variant="text"
                      component="label"
                      startIcon={<ImageAddIcon />}
                      sx={{ minWidth: 0, padding: 1 }}
                      disabled={isFormDisabled}
                    >
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        multiple
                        onChange={handleSelectImage}
                      />
                    </Button>

                    <Button
                      variant="text"
                      startIcon={<ImageViewIcon />}
                      onClick={() => console.log("View Image Clicked")}
                      sx={{ minWidth: 0, padding: 1 }}
                      disabled={isFormDisabled}
                    /> */}

                    <Button
                      variant="text"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleItemRemove(item)}
                      sx={{ minWidth: 0, padding: 1, color: "secondary.main" }}
                      disabled={isFormDisabled}
                    />
                  </Box>
                }
              >
                <ItemImage
                  src={
                    item.imageUrls[0]
                      ? `${config.backendDomain}/${item.imageUrls[0]}`
                      : "/assets/customIcons/inventory_item_default.ico"
                  }
                  alt={item.description}
                />
                <ListItemText
                  primary={item.roomName}
                  secondary={
                    <>
                      {item.description && (
                        <Description>{item.description}</Description>
                      )}
                      {item.placeOfPurchase && (
                        <span
                          style={{ marginLeft: "2px" }}
                        >{`Purchased in ${item.placeOfPurchase}`}</span>
                      )}
                      {item.insuredOrigCost && (
                        <span
                          style={{ marginLeft: "2px" }}
                        >{`, Insurance Orig Cost: ${item.insuredOrigCost}${item.insuredOrigCostCurrency}`}</span>
                      )}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid2>

        <Grid2 item="true" size={{ xs: 12, sm: 12 }}>
          <TextField
            label="Estimated Loss Value"
            name="estimatedLossValue"
            value={insuranceClaimData.estimatedLossValue || ""}
            onChange={handleChange}
            fullWidth
            required
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 12, sm: 12 }}>
          <TextField
            label="Additional Notes"
            name="additionalNotes"
            value={insuranceClaimData.additionalNotes || ""}
            onChange={handleChange}
            fullWidth
            multiline
            rows={2}
            disabled={isFormDisabled}
          />
        </Grid2>
      </Grid2>
      
      {/* Image Upload Section */}
      {/* <Grid2
        size={{ xs: 12, sm: 4 }}
        container
        direction="column"
        alignItems={{ xs: "center", sm: "flex-start" }}
      >
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
          fullWidth
          sx={{ marginBottom: 2 }}
          disabled={isFormDisabled}
        >
          Select Images
          <input
            type="file"
            hidden
            accept="image/*"
            multiple
            onChange={handleSelectImage}
          />
        </Button>

        <Grid2 container spacing={2}>
          <ImagesPreview
            imagePreviews={imagePreviews}
            titles={titles}
            handleDeleteImage={handleDeleteImage}
            disabled={isFormDisabled}
          />
        </Grid2>
      </Grid2> */}
    </Grid2>
  );
};

export default InsuranceClaimInputFields;
