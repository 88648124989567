const styles = {
  chatWindowStyle: {
    backgroundColor: "#f2f2f2",
  },
  fileAttachmentIconStyle: {
    backgroundColor: "darkturquoise"
  }
  // ...other styles
};

export default styles;
