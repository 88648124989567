//! Predefined consts
const currencies = ["USD", "AUD", "EUR", "GBP"]; // Add more currencies if needed
const brands = ["ACG", "Acme", "Acme United", "Acme Westcott"];
const conditions = ["New", "Excellent", "Good", "Fair"];
const statusOptions = ["ALW", "DSP"];
const roomTypes = [
  "Attic",
  "Basement/Cellar",
  "Bathroom",
  "Bedroom",
  "Closet",
  "Commercial Space",
  "Dining Room",
  "Family/Den/Rec Room",
  "Foyer/Entry/Stairs",
  "Garage",
  "Great Room",
  "Hallway/Passage",
  "Hobby/Exercise Room",
  "Kitchen",
  "Laundry Room",
  "Living Room",
  "Master Bedroom",
  "Media Room",
  "Office/Study/Library",
  "Out of Sight Items",
  "Outside Space",
  "Pantry",
  "Porch",
  "Single Summary",
  "Storage Room",
  "Sun Room",
  "Supplement",
  "Utility Room",
];

module.exports = {
  currencies,
  brands,
  conditions,
  statusOptions,
  roomTypes,
};
