import { createTheme } from "@mui/material/styles";

// const iPhoneInput = {
//   styleOverrides: {
//     root: {
//       "*": {
//         "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
//         "-moz-user-select": "text !important" /* Firefox 2+ */,
//         "-ms-user-select": "text !important" /* IE 10+ */,
//         "user-select": "text !important" /* Standard syntax */,
//       },
//     },
//   },
// };

// A custom theme for this app
const theme = createTheme({
  palette: {
    header: {
      main: "#262f30",
      secondary: "#7B68EE",
    },
    background: {
      default: "white",
    },
    title: {
      main: "#ffffff", // Bold white for title
    },
    subtitle: {
      main: "#ffa500", // Soft orange for subtitle
    },
    description: {
      main: "#8b8b8b", // Dark grey for description
    },
  },
  components: {
    // MuiTextField: iPhoneInput,
    // MuiInput: iPhoneInput,
    // MuiFilledInput: iPhoneInput,
    // MuiOutlinedInput: iPhoneInput,

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
