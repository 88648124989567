import axios from "axios";
import config from "../../settings/config";
import { getToken } from "../../settings/storage";

// Process insurance content document (PDF, DOCX, or TXT)
export const chatWithChatbot = async (payload) => {
  try {
    const token = await getToken();

    const response = await axios.post(
      `${config.backendUrl}/client/chat/`,
      { user_chat: payload.userChat },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'multipart/form-data' // Axios automatically sets this for FormData
        }
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error interacting with AI chatbot:", error);
    throw error;
  }
};