import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatBot from "react-chatbotify";
import { Box } from "@mui/material";

import { AuthContext } from "../../../contexts/AuthContext";
import SavedInventory from "../../Profile/SavedInventory";
import flowComposer from "../config/flow";
import styles from "../config/styles";
import settings from "../config/settings";
import "./chatbot.css";

const ChatBotWrapper = () => {
  const navigate = useNavigate();
  const { authorized, user, loading } = useContext(AuthContext);

  const unauthorizedHandler = () => navigate("/sign-in");

  useEffect(() => {
    if (!loading && !authorized) {
      // Redirect to sign-in if the user is not authorized after loading is complete
      navigate("/sign-in")
    }
  }, [loading, authorized, navigate]);
  useEffect(() => {
    if (!loading) {
      console.log("checkpoint user changed:", user);
    }
  }, [user, loading]);

  const listItemsHandler = (props) => {
    props.goToPath("request_start_again");

    return (
      <Box
        sx={{
          p: 3,
          borderRadius: "22px",
          borderColor: "#491d8d",
          border: "2px outset #491d8d",
          color: "white",
          marginTop: "8px",
          marginRight: { xs: "32px", sm: "64px" },
          marginLeft: "16px",
          overflow: {
            xs: "auto", // Allow horizontal scroll on xs and sm
            sm: "auto",
            md: "hidden", // No scroll on medium and larger screens
          },
        }}
      >
        <SavedInventory unauthorizedHandler={unauthorizedHandler} />
      </Box>
    );
  };
  const flow = flowComposer(user, unauthorizedHandler, listItemsHandler);
  console.log("checkpoint loading:", loading);
  console.log("checkpoint userData:", user);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <ChatBot styles={styles} settings={settings} flow={flow} />;
};

export default ChatBotWrapper;
