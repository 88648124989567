import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Paper,
  InputBase,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  MenuItem,
  Select,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import InventoryInputDialog from "../utils/InventoryInputDialog";
import InventoryViweDialog from "../utils/InventoryViewDialog";
import InventoryTable from "../utils/InventoryTable";

import {
  fetchInventoryItems,
  deleteInventoryItem,
  createInventoryItem,
  updateInventoryItem,
} from "../../services/client/inventoryItemsService";
import { currencies } from "../../consts/categories";
import { convertSnakeToCamel } from "../../utils/jsonHelpers";

const initialInventoryData = {
  quantity: 1,
  internalComments: "",
  description: "",
  brand: "",
  model: "",
  placeOfPurchase: "",
  insuredOrigCost: "",
  insuredOrigCostCurrency: currencies ? currencies[0] : "USD",
  insuredReplacementPrice: "",
  insuredReplacementCurrency: currencies ? currencies[0] : "USD",
  ageYears: "",
  ageMonths: "",
  purchaseDate: "",
  condition: "",
  antique: "",
  roomType: "",
  roomName: "",
};

export default function SavedInventory({ unauthorizedHandler }) {
  // State variables
  const [mounted, setMounted] = useState(false); // To ensure component only renders on client

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortBy, setSortBy] = useState("updated_at");
  const [sortOrder, setSortOrder] = useState("desc");

  const [search, setSearch] = useState("");
  const [delayedSearch, setDelayedSearch] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [inputDialogOpen, setInputDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(initialInventoryData); // For editing
  const [selectedRoom, setSelectedRoom] = useState(""); // For room selection

  const fetchInventoryData = useCallback(async () => {
    const sortOrderNum = sortOrder === "asc" ? 1 : -1;
    try {
      const response = await fetchInventoryItems(
        page + 1,
        rowsPerPage,
        delayedSearch,
        selectedRoom,
        sortBy,
        sortOrderNum
      );

      setRows(
        response.data.inventory_items.map((item) => convertSnakeToCamel(item))
      );
      setTotalCount(response.data.total_count); // Set total count from response
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.detail || "An error occurred", {
          autoClose: 1000,
        });
        if (error.response.status === 401) {
          unauthorizedHandler();
        }
      } else {
        toast.error("Network error or request failed", { autoClose: 1000 });
      }
    }
  }, [
    page,
    rowsPerPage,
    delayedSearch,
    unauthorizedHandler,
    selectedRoom,
    sortBy,
    sortOrder,
  ]);

  useEffect(() => {
    setMounted(true); // Indicate that the component has mounted
  }, []);

  useEffect(() => {
    fetchInventoryData();
  }, [fetchInventoryData]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayedSearch(search);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const closeInputDialog = () => {
    setInputDialogOpen(false);
    setSelectedItem(initialInventoryData); // Reset selected item
  };

  const closeViewDialog = () => {
    setViewDialogOpen(false);
    setSelectedItem(initialInventoryData); // Reset selected item
  };

  const openConfirmationDialog = (id) => {
    setDeleteItemId(id);
    setConfirmationDialogOpen(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const saveInventoryData = async (data) => {
    try {
      if (selectedItem && selectedItem.id) {
        await updateInventoryItem(selectedItem.id, data);
        toast.success("Inventory item updated successfully!", {
          autoClose: 1000,
        });
      } else {
        await createInventoryItem(data);
        toast.success("Inventory item created successfully!", {
          autoClose: 1000,
        });
      }
      fetchInventoryData();
      setInputDialogOpen(false);
      setSelectedItem(initialInventoryData);
    } catch (error) {
      toast.error(error.message, { autoClose: 1000 });
      if (error.response && error.response.status === 401) {
        unauthorizedHandler();
      }
    }
  };

  const handleUpdateInventoryData = (updater) => {
    setSelectedItem(
      (prevState) => updater(prevState) // Call the updater function with the previous state
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  // --- view/edit/delete actions handlers
  const handleViewItem = (item) => {
    setSelectedItem(item);
    setViewDialogOpen(true);
  };

  const handleEditItem = (item) => {
    setSelectedItem(item);
    setInputDialogOpen(true);
  };

  const handleDeleteItem = async () => {
    try {
      await deleteInventoryItem(deleteItemId);
      toast.success("Inventory item deleted successfully!", {
        autoClose: 1000,
      });
      setConfirmationDialogOpen(false);
      fetchInventoryData();
    } catch (error) {
      toast.error(error.message, { autoClose: 1000 });
      if (error.response && error.response.status === 401) {
        unauthorizedHandler();
      }
    }
  };

  if (!mounted) return null;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography
        variant="h5"
        color="text.primary"
        sx={{ mb: 1, fontWeight: "bold" }}
      >
        Saved Inventory Items
      </Typography>
      <Box>
        <Box
          sx={{
            pb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Paper
            component="form"
            sx={{ p: "0px 4px", display: "flex", alignItems: "center", mb: 2 }}
            onSubmit={(e) => e.preventDefault()}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "8px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <Select
              value={selectedRoom}
              onChange={(e) => setSelectedRoom(e.target.value)}
              displayEmpty
              sx={{ ml: 1 }}
            >
              <MenuItem value="">
                <em>All Rooms</em>
              </MenuItem>
              {/* {roomTypes.map((room) => (
                <MenuItem key={room} value={room}>
                  {room}
                </MenuItem>
              ))} */}
            </Select>
          </Paper>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setInputDialogOpen(true)}
            sx={{ ml: 2 }} // Add some margin on the left for spacing
          >
            Add New Item
          </Button>
        </Box>

        <InventoryTable
          rows={rows}
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0); // Reset to first page
          }}
          onSort={handleRequestSort}
          onView={(id) => handleViewItem(rows.find((row) => row.id === id))} // Placeholder for view function
          onEdit={(id) => handleEditItem(rows.find((row) => row.id === id))}
          onDelete={(id) => openConfirmationDialog(id)}
        />
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationDialogOpen} onClose={closeConfirmationDialog}>
        <DialogTitle>
          {"Are you sure you want to delete this item?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeConfirmationDialog}>Cancel</Button>
          <Button onClick={handleDeleteItem}>Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Inventory Input Form */}
      <InventoryInputDialog
        open={inputDialogOpen}
        title={
          selectedItem && selectedItem.id
            ? "Edit Inventory Item"
            : "Add Inventory Item"
        }
        inventoryData={selectedItem}
        handleUpdateData={handleUpdateInventoryData}
        handleClose={closeInputDialog}
        saveInventoryData={saveInventoryData}
      />

      {/* Inventory View Form */}
      <InventoryViweDialog
        open={viewDialogOpen}
        title="Detailed inventory item"
        inventoryData={selectedItem ? selectedItem : initialInventoryData}
        handleClose={closeViewDialog}
      />
    </Box>
  );
}
