import React, { createContext, useState, useEffect } from "react";
import { getToken, removeToken } from "../settings/storage";
import { isTokenExpired } from "../settings/verify-token";
import { getUserProfile } from "../services/client/profileService";

// Create a context
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [authorized, setAuthorized] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const checkAuth = async () => {
      const token = await getToken();
      if (token && !isTokenExpired(token)) {
        try {
          const response = await getUserProfile()
          if (response && response.data) {
            setUser(response.data);
            setAuthorized(true);
          }
        } catch (error) {
          console.error("Failed to fetch profile:", error);
          setAuthorized(false);
          removeToken();
        }
      } else {
        setAuthorized(false);
        removeToken();
      }
      setLoading(false);
    };
    checkAuth();
  }, []);

  const signOut = async () => {
    removeToken();
    setAuthorized(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ authorized, user, signOut, setAuthorized, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
