import React, { useState, useEffect } from "react";

import { Button, Grid2, Box, CircularProgress } from "@mui/material";

//! Predefined consts
import {
  currencies,
  // brands,
  // conditions,
  // roomTypes,
} from "../../consts/categories";
import InventoryInputFields from "./InventoryInputFields";

const initialInventoryMainData = {
  quantity: 1,
  internalComments: "",
  description: "",
  brand: "",
  model: "",
  placeOfPurchase: "",
  insuredOrigCost: "",
  insuredOrigCostCurrency: currencies ? currencies[0] : "USD",
  insuredReplacementPrice: "",
  insuredReplacementCurrency: currencies ? currencies[0] : "USD",
  ageYears: "",
  ageMonths: "",
  purchaseDate: "",
  condition: "",
  antique: "",
};

const InventoryInputForm = ({ onSubmit }) => {
  const initialInventoryData = {
    ...initialInventoryMainData,
    roomType: "",
    roomName: "",
  };
  const [loading, setLoading] = useState(false);
  const [inventoryData, setInventoryData] = useState(initialInventoryData);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [isExit, setIsExit] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [mounted, setMounted] = useState(false); // To ensure component only renders on client

  useEffect(() => {
    setMounted(true); // Indicate that the component has mounted
  }, []);

  useEffect(() => {
    if (isExit) {
      onSubmit({ isExit: true });
      setIsExit(false); // Reset the action after triggering exit action
    }
  }, [isExit, onSubmit]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInventoryData((prevState) => ({ ...prevState, [name]: value }));
  };
  // const handleBrandChange = (e, value) => {
  //   setInventoryData((prevState) => ({
  //     ...prevState,
  //     brand: value,
  //   }));
  // };
  // const handleRoomTypeChange = (e, value) => {
  //   setInventoryData((prevState) => ({
  //     ...prevState,
  //     roomType: value,
  //   }));
  // };
  const handleSelectImage = (e) => {
    const files = Array.from(e.target.files); // Get all selected files
    setSelectedImages((prevImages) => [...prevImages, ...files]);

    // Generate image previews
    const previews = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result);
      });
    });

    // Wait for all previews to be generated before setting state
    Promise.all(previews).then((newImagePreviews) => {
      setImagePreviews((prevPreviews) => [
        ...prevPreviews,
        ...newImagePreviews,
      ]);
    });
  };
  const handleDeleteImage = (index) => {
    // Remove image and corresponding preview
    setSelectedImages((prevImages) =>
      prevImages.filter((_, imgIndex) => imgIndex !== index)
    );
    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, previewIndex) => previewIndex !== index)
    );
  };

  // Set eventType to 0 for exit
  const handleExitClick = () => {
    setIsFormDisabled(true);
    setIsExit(true);
  };

  // Initilize when next room clicked
  const handleNewRoomClick = () => {
    setInventoryData(initialInventoryData);
    setImagePreviews([]);
    setSelectedImages([]);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // // Basic validation
    // if (!inventoryData.roomName || !inventoryData.description) {
    //   setSnackMessage("Room Name and Description are required.");
    //   setSnackOpen(true);
    //   return;
    // }

    setLoading(true);
    try {
      await onSubmit({ inventory_item: inventoryData, images: selectedImages });
      // Reset form
      setInventoryData(initialInventoryData);
      setImagePreviews([]);
      setSelectedImages([]);
    } catch (error) {
      console.error("Failed to create inventory item:", error.message);
      // setSnackMessage(`Error: ${error.message}`);
      // setSnackOpen(true);
    } finally {
      setLoading(false);
    }
  };

  if (!mounted) return null;
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      onTouchStart={(e) => {
        if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
          e.target.focus();
        }
      }}
      sx={{
        p: 3,
        borderRadius: "22px",
        borderColor: "#42B4C5",
        bgcolor: "white",
        marginTop: "8px",
        marginLeft: { xs: "32px", sm: "64px" },
        marginRight: "16px",
      }}
    >
      <InventoryInputFields
        inventoryData={inventoryData}
        imagePreviews={imagePreviews}
        titles={selectedImages.map((image) => image.name)}
        handleChange={handleChange}
        handleNewRoomClick={handleNewRoomClick}
        handleDeleteImage={handleDeleteImage}
        handleSelectImage={handleSelectImage}
        isFormDisabled={isFormDisabled}
      />

      {/* Group: Submit Buttons */}
      <Grid2 container size={{ xs: 12, sm: 12 }} justifyContent="flex-end">
        {/* Create Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isFormDisabled}
        >
          {loading ? <CircularProgress size={24} /> : "Create"}
        </Button>

        {/* Exit Button */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleExitClick}
          disabled={isFormDisabled}
        >
          Exit
        </Button>
      </Grid2>
    </Box>
  );
};

export default InventoryInputForm;
