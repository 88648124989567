import React, { useState, useEffect } from "react";
import {
  Box,
  Grid2,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import InventoryInputFields from "../utils/InventoryInputFields";
import config from "../../settings/config"

export default function InventoryInputDialog({
  open,
  title,
  inventoryData,
  handleUpdateData,
  saveInventoryData,
  handleClose,
}) {
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    if (inventoryData.imageUrls && inventoryData.imageUrls.length > 0) {
      const fetchImages = async () => {
        const urls = inventoryData.imageUrls.map((url) => `${config.backendDomain}/${url}`)
        const files = await Promise.all(
          urls.map(async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            const fileName = url.split("/").pop(); // Extract file name from URL
            return new File([blob], fileName, { type: blob.type });
          })
        );
        setSelectedImages(files);
        setImagePreviews(urls);
      };
      fetchImages();
    }
  }, [inventoryData.imageUrls]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleUpdateData((prevState) => ({ ...prevState, [name]: value || "" }));
  };

  const handleSelectImage = (e) => {
    const files = Array.from(e.target.files); // Get all selected files
    setSelectedImages((prevImages) => [...prevImages, ...files]);

    // Generate image previews
    const previews = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result);
      });
    });

    // Wait for all previews to be generated before setting state
    Promise.all(previews).then((newImagePreviews) => {
      setImagePreviews((prevPreviews) => [
        ...prevPreviews,
        ...newImagePreviews,
      ]);
    });
  };
  const handleDeleteImage = (index) => {
    // Remove image and corresponding preview
    setSelectedImages((prevImages) =>
      prevImages.filter((_, imgIndex) => imgIndex !== index)
    );
    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, previewIndex) => previewIndex !== index)
    );
  };

  // Initilize when next room clicked
  const handleNewRoomClick = () => {
    handleUpdateData((prevState) => ({
      ...prevState,
      roomType: "",
      roomName: "",
    }));
    setImagePreviews([]);
    setSelectedImages([]);
  };

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { inventory_item: inventoryData, images: selectedImages };
    await saveInventoryData(data);
    // setInventoryData((prevState) => ({
    //   ...prevState,
    //   ...initialInventoryMainData,
    // }));
    setImagePreviews([]);
    setSelectedImages([]);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit}
          onTouchStart={(e) => {
            if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
              e.target.focus();
            }
          }}
          sx={{
            p: 3,
            borderRadius: "22px",
            borderColor: "#42B4C5",
            bgcolor: "white",
            marginTop: "8px",
            marginLeft: { xs: "32px", sm: "64px" },
            marginRight: "16px",
          }}
        >
          <InventoryInputFields
            inventoryData={inventoryData}
            imagePreviews={imagePreviews}
            titles={selectedImages.map((image) => image.name)}
            handleChange={handleChange}
            handleSelectImage={handleSelectImage}
            handleDeleteImage={handleDeleteImage}
            handleNewRoomClick={handleNewRoomClick}
          />
          <Grid2
            container
            size={{ xs: 12, sm: 12 }}
            marginTop={3}
            spacing={1}
            justifyContent="flex-end"
          >
            {/* Create Button */}
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>

            {/* Exit Button */}
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Grid2>
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions> */}
    </Dialog>
  );
}
