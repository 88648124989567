const WELCOME_MESSAGE = `Hello! Welcome to HomeGuard AI, your smart companion for home inventory and insurance claims. 👋
I’m here to help you keep track of your belongings, analyze your insurance, and assist with any claims you may need to make.`;

const START_MESSAGE = "How can I assist you today?";
const START_AGAIN_MESSAGE = "What else do you need?";
const START_NEW_OPTION = "Manage home inventory";
const START_NEW_RESPONSE_MESSAGE =
  "Great! Let’s get started on your home inventory. I’ll guide you through each room, helping you log your belongings. You can also upload photos or scans for easy tracking.";
const START_CONTINUE_OPTION = "Continue with my home inventory";
const START_CONTINUE_RESPONSE_MESSAGE =
  "Welcome back! I see that you’ve already started an inventory. Which room would you like to continue with?";
const START_ANALYZE_OPTION = "Analyze my insurance policy";
const START_ANALYZE_RESPONSE_MESSAGE =
  "I can help you understand your insurance policy better.";
const START_HELP_OPTION = "Help with an insurance claim";
const START_HELP_RESPONS_MESSAGE =
  "I’m here to help you file an insurance claim. Let’s get started with some basic details.";
const START_CHAT_FREELY_OPTION = "Chat freely with AI assistant"
const START_CHAT_FREELY_RESPONSE_MESSAGE = "How can I help you?";

const REQUEST_INVENTORY_INPUT_FORM_MESSAGE =
  "Let’s begin by documenting room-by-room. Please fill the inventory form about each item you want to save. 😊";
const INVENTORY_INPUT_YES_RESPONSE_MESSAGE =
  "Great! Please fill the inventory form about each item you want to save. 😊";
const INVENTORY_INPUT_NO_RESPONSE_MESSAGE =
  "Ok. Do you need to chat freely? I am always with you!";
const INVENTORY_INPUT_CAUTION_MESSAGE =
  " ⚠ If you need to create new inventory item, select [Create] button. Otherwise, select [Exit] button.";
const INVENTORY_CREATE_OK_MESSAGE = "Ok, you created the inventory item!";
const INVENTORY_INPUT_EXIT_RESPONSE_MESSAGE =
  "Now, you can list your inventory items, or chat freely with me.";
const USER_LIST_ITEMS_MESSAGE = "I want to list the items.";
const LIST_ITEMS_RESPONSE_MESSAGE =
  "Ok, let's reivew the items. You can edit or delete the items.";
const USER_START_AGAIN_MESSAGE = "I want to do other things.";
const START_AGAIN_RESPONSE_MESSAGE = "😊 I will help you with my best.";

const REQUEST_INSURANCE_POLICY_UPLOAD_MESSAGE = "Please upload your correct policy documents(PDF, docx, txt), and I’ll read and analyze them for you."
const REQUEST_INSURANCE_CLAIM_INPUT_MESSAGE = "Input the information related to your insurance claim in the following form."
const INSURANCE_CLAIM_INPUT_EXIT_RESPONSE_MESSAGE = "Everything is okay with my service?"

const INVENTORY_ITEMS_LIST_MESSAGE = "The inventory items list will be showed soon.";
const UNKNOWN_INPUT__RESPONSE_MESSAGE =
  "Sorry, I do not understand your message 😢! If you require further assistance, you may click on the 'Yes' option.";

module.exports = {
  WELCOME_MESSAGE,

  START_MESSAGE,
  START_NEW_OPTION,
  START_NEW_RESPONSE_MESSAGE,
  START_CONTINUE_OPTION,
  START_CONTINUE_RESPONSE_MESSAGE,
  START_ANALYZE_OPTION,
  START_ANALYZE_RESPONSE_MESSAGE,
  START_HELP_OPTION,
  START_HELP_RESPONS_MESSAGE,
  START_CHAT_FREELY_OPTION,
  START_CHAT_FREELY_RESPONSE_MESSAGE,

  REQUEST_INVENTORY_INPUT_FORM_MESSAGE,
  INVENTORY_INPUT_YES_RESPONSE_MESSAGE,
  INVENTORY_INPUT_NO_RESPONSE_MESSAGE,
  INVENTORY_INPUT_CAUTION_MESSAGE,
  INVENTORY_CREATE_OK_MESSAGE,
  INVENTORY_INPUT_EXIT_RESPONSE_MESSAGE,

  REQUEST_INSURANCE_POLICY_UPLOAD_MESSAGE,

  REQUEST_INSURANCE_CLAIM_INPUT_MESSAGE,
  INSURANCE_CLAIM_INPUT_EXIT_RESPONSE_MESSAGE,

  USER_LIST_ITEMS_MESSAGE,
  LIST_ITEMS_RESPONSE_MESSAGE,
  USER_START_AGAIN_MESSAGE,
  START_AGAIN_RESPONSE_MESSAGE,
  INVENTORY_ITEMS_LIST_MESSAGE,

  START_AGAIN_MESSAGE,
  UNKNOWN_INPUT__RESPONSE_MESSAGE,
};
