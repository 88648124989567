import React from "react";
import {
  Table,
  TableSortLabel,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Box,
  Chip,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ViewIcon from "@mui/icons-material/Visibility";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { visuallyHidden } from "@mui/utils";

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  fontSize: "1rem",
  fontWeight: "bold",
}));
const StickyTableCellHead = styled(StyledTableCellHead)(({ theme }) => ({
  position: "sticky",
  zIndex: 2,
}));
// Pin the first column to the left
const FirstColumnHead = styled(StickyTableCellHead)({
  left: 0,
  zIndex: 3, // Higher z-index to keep it above other cells
});
// Pin the last column to the right
const LastColumnHead = styled(StickyTableCellHead)({
  right: 0,
  zIndex: 3,
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "1rem",
}));
// Sticky styles for pinned columns
const StickyTableCell = styled(StyledTableCell)(({ theme }) => ({
  position: "sticky",
  zIndex: 0,
}));
// Pin the first column to the left
const FirstColumnCell = styled(StyledTableCell)({
  position: "sticky",
  left: 0,
  zIndex: 1, // Higher z-index to keep it above other cells
  background: "white",
});
// Pin the last column to the right
const LastColumnCell = styled(StyledTableCell)({
  position: "sticky",
  right: 0,
  zIndex: 1,
  background: "white",
});

const headCells = [
  {
    id: "room_name",
    numeric: false,
    disablePadding: true,
    label: "Room Name",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Item Description",
  },
  {
    id: "internal_comments",
    numeric: false,
    disablePadding: false,
    label: "Internal Comments",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "antique",
    numeric: false,
    disablePadding: false,
    label: "Antique",
  },
  {
    id: "purchase_date",
    numeric: false,
    disablePadding: false,
    label: "Purchase Date",
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "Updated Date",
  },
];

const formatDateTime = (dateString) => {
  const date = new Date(dateString);

  // Extract components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Format as "YYYY-MM-DD HH:mm:ss"
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

function InventoryTable({
  rows,
  totalCount,
  rowsPerPage,
  page,
  sortBy,
  sortOrder,

  onPageChange,
  onRowsPerPageChange,
  onSort,

  onView,
  onEdit,
  onDelete,
}) {
  const handleSort = (property) => (event) => {
    onSort(event, property);
  };

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0); // Adjust for zero-based index
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(totalCount / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          overflowX: {
            xs: "auto", // Allow horizontal scroll on xs and sm
            sm: "auto",
            md: "hidden", // No scroll on medium and larger screens
          },
          flexGrow: 1,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <FirstColumnHead
                align="left"
                padding="none"
                sortDirection={sortBy === headCells[0].id ? sortOrder : false}
              >
                <TableSortLabel
                  active={sortBy === headCells[0].id}
                  direction={sortBy === headCells[0].id ? sortOrder : "desc"}
                  onClick={handleSort(headCells[0].id)}
                >
                  {headCells[0].label}
                  {sortBy === headCells[0].id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {sortOrder === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </FirstColumnHead>
              {headCells.slice(1).map((headCell) => (
                <StyledTableCellHead
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={sortBy === headCell.id ? sortOrder : false}
                >
                  <TableSortLabel
                    active={sortBy === headCell.id}
                    direction={sortBy === headCell.id ? sortOrder : "desc"}
                    onClick={handleSort(headCell.id)}
                  >
                    {headCell.label}
                    {sortBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sortOrder === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCellHead>
              ))}
              <LastColumnHead align="right">Action</LastColumnHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <FirstColumnCell>{row.roomName}</FirstColumnCell>
                <StickyTableCell>{row.description}</StickyTableCell>
                <StickyTableCell>{row.internalComments}</StickyTableCell>
                <StickyTableCell align="center">{row.quantity}</StickyTableCell>
                <StickyTableCell align="center">
                  <Chip
                    label={row.antique ? "Yes" : "No"}
                    size="small"
                    color={row.antique ? "primary" : "default"}
                  />
                </StickyTableCell>
                <StickyTableCell align="center">
                  {row.purchaseDate}
                </StickyTableCell>
                <StickyTableCell align="center">
                  {formatDateTime(row.updatedAt)}
                </StickyTableCell>
                <LastColumnCell align="right">
                  <IconButton color="primary" onClick={() => onView(row.id)}>
                    <ViewIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => onEdit(row.id)}>
                    <EditNoteIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => onDelete(row.id)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </LastColumnCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              ActionsComponent={() => (
                <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                  <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(totalCount / rowsPerPage) - 1}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                  <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(totalCount / rowsPerPage) - 1}
                  >
                    <LastPageIcon />
                  </IconButton>
                </Box>
              )}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}

export default InventoryTable;
