import React from "react";
import ReactMarkdown from "react-markdown";
import { Box } from "@mui/material";

const FormattedTextView = ({ text }) => {
  return (
    <Box
      sx={{
        p: 3,
        borderRadius: "22px",
        borderColor: "#42B4C5",
        bgcolor: "#773ad6",
        color: "white",
        marginTop: "8px",
        marginRight: { xs: "32px", sm: "64px" },
        marginLeft: "16px",
      }}
    >
      <ReactMarkdown>{text}</ReactMarkdown>
    </Box>
  );
};

export default FormattedTextView;
