import React, { useState, useEffect } from "react";
import { Button, Grid2, Box, CircularProgress } from "@mui/material";

import InsuranceClaimInputFields from "./InsuranceClaimInputFields";

const initialInsuranceClaimMainData = {
  incidentDate: "",
  causeOfLoss: "",
  incidentLocation: "",
  moaffectedItems: "",
  affectedItems: [],
  estimatedLossValue: "",
  additionalNotes: "",
};

const InsuranceClaimInputForm = ({
  userData,
  onSubmit,
  unauthorizedHandler,
}) => {
  const initialInsuranceClaimData = {
    ...initialInsuranceClaimMainData,
    claimantName:
      userData && userData.first_name
        ? `${userData.first_name} ${userData.last_name}`
        : "",
    claimantEmail: userData && userData.email ? userData.email : "",
  };
  const [submitting, setSubmitting] = useState(false);
  const [insuranceClaimData, setInsuranceClaimData] = useState(
    initialInsuranceClaimData
  );
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [isExit, setIsExit] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [mounted, setMounted] = useState(false); // To ensure component only renders on client

  useEffect(() => {
    setMounted(true); // Indicate that the component has mounted
  }, []);

  useEffect(() => {
    if (isExit) {
      onSubmit({ isExit: true });
      setIsExit(false); // Reset the action after triggering exit action
    }
  }, [isExit, onSubmit]);

  useEffect(() => {
    console.log('checkpoint user data changed:', userData)
    if (userData) {
      // Populate claimantName and claimantEmail when user data is available
      setInsuranceClaimData((prevState) => ({
        ...prevState,
        claimantName: userData.first_name
          ? `${userData.first_name} ${userData.last_name}`
          : "",
        claimantEmail: userData.email || "",
      }));
    }
  }, [userData]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInsuranceClaimData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSelectImage = (e) => {
    const files = Array.from(e.target.files); // Get all selected files
    setSelectedImages((prevImages) => [...prevImages, ...files]);

    // Generate image previews
    const previews = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result);
      });
    });

    // Wait for all previews to be generated before setting state
    Promise.all(previews).then((newImagePreviews) => {
      setImagePreviews((prevPreviews) => [
        ...prevPreviews,
        ...newImagePreviews,
      ]);
    });
  };
  const handleDeleteImage = (index) => {
    // Remove image and corresponding preview
    setSelectedImages((prevImages) =>
      prevImages.filter((_, imgIndex) => imgIndex !== index)
    );
    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, previewIndex) => previewIndex !== index)
    );
  };

  // Set eventType to 0 for exit
  const handleExitClick = () => {
    setIsFormDisabled(true);
    setIsExit(true);
  };

  // Initilize when next room clicked
  const handleNewRoomClick = () => {
    setInsuranceClaimData(initialInsuranceClaimData);
    setImagePreviews([]);
    setSelectedImages([]);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // // Basic validation
    // if (!insuranceClaimData.roomName || !insuranceClaimData.description) {
    //   setSnackMessage("Room Name and Description are required.");
    //   setSnackOpen(true);
    //   return;
    // }

    setSubmitting(true);
    try {
      await onSubmit({
        claim_info: insuranceClaimData,
        images: selectedImages,
      });
      // Reset form
      setInsuranceClaimData(initialInsuranceClaimData);
      setImagePreviews([]);
      setSelectedImages([]);
    } catch (error) {
      console.error("Failed to create inventory item:", error.message);
      // setSnackMessage(`Error: ${error.message}`);
      // setSnackOpen(true);
    } finally {
      setSubmitting(false);
    }
  };

  if (!mounted) return null;
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      onTouchStart={(e) => {
        if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
          e.target.focus();
        }
      }}
      sx={{
        p: 3,
        borderRadius: "22px",
        borderColor: "#42B4C5",
        bgcolor: "white",
        marginTop: "8px",
        marginLeft: { xs: "32px", sm: "64px" },
        marginRight: "16px",
      }}
    >
      <Grid2 container spacing={1}>
        <Grid2>
          <InsuranceClaimInputFields
            insuranceClaimData={insuranceClaimData}
            imagePreviews={imagePreviews}
            titles={selectedImages.map((image) => image.name)}
            handleChange={handleChange}
            handleNewRoomClick={handleNewRoomClick}
            handleDeleteImage={handleDeleteImage}
            handleSelectImage={handleSelectImage}
            unauthorizedHandler={unauthorizedHandler}
            isFormDisabled={isFormDisabled}
          />
        </Grid2>

        {/* Group: Submit Buttons */}
        <Grid2
          container
          size={{ xs: 12, sm: 12 }}
          spacing={1}
          justifyContent="flex-end"
        >
          {/* Create Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isFormDisabled}
          >
            {submitting ? (
              <CircularProgress size={24} />
            ) : (
              "Generate insurance claim summary"
            )}
          </Button>

          {/* Exit Button */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleExitClick}
            disabled={isFormDisabled}
          >
            Exit
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default InsuranceClaimInputForm;
