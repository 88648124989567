import axios from "axios";
import config from "../../settings/config";
import { getToken } from "../../settings/storage";

// Get user profile (inventory items and jobs)
export const getUserProfile = async () => {
  try {
    const token = await getToken();

    const response = await axios.get(`${config.backendUrl}/client/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

// Update user profile
export const updateUserProfile = async (profileData) => {
  try {
    const token = await getToken();

    const response = await axios.put(`${config.backendUrl}/client/profile`, profileData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
};

// Change user password
export const changeUserPassword = async (passwordData) => {
  try {
    const token = await getToken();

    const response = await axios.put(
      `${config.backendUrl}/client/profile/change-password`,
      passwordData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error changing password:", error);
    throw error;
  }
};

// Get user's active subscription
export const getUserSubscription = async () => {
  try {
    const token = await getToken();

    const response = await axios.get(`${config.backendUrl}/client/profile/active_subscription`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching user subscription:", error);
    throw error;
  }
};

// Set up a new payment method
export const setupPaymentMethod = async () => {
  try {
    const token = await getToken();

    const response = await axios.post(`${config.backendUrl}/client/profile/payment_method`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error setting up payment method:", error);
    throw error;
  }
};

// Retrieve user's payment method
export const getPaymentMethod = async () => {
  try {
    const token = await getToken();

    const response = await axios.get(`${config.backendUrl}/client/profile/payment_method`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching payment method:", error);
    throw error;
  }
};

// Update user's payment method
export const updatePaymentMethod = async (paymentMethodId) => {
  try {
    const token = await getToken();

    const response = await axios.put(
      `${config.backendUrl}/client/profile/payment_method`,
      { payment_method_id: paymentMethodId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error updating payment method:", error);
    throw error;
  }
};

// Delete user's payment method
export const deletePaymentMethod = async () => {
  try {
    const token = await getToken();

    const response = await axios.delete(`${config.backendUrl}/client/profile/payment_method`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error deleting payment method:", error);
    throw error;
  }
};
